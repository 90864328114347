export const isInViewport = (elem: HTMLElement) => {
	if (!elem) {
		return false;
	}

	const bounding = elem.getBoundingClientRect();
	const height = window.innerHeight || document.documentElement.clientHeight;
	const width = window.innerWidth || document.documentElement.clientWidth;

	return (
		((bounding.top > 0 && bounding.top <= height) ||
			(bounding.bottom > 0 && bounding.bottom <= height)) &&
		((bounding.left > 0 && bounding.left <= width) ||
			(bounding.right > 0 && bounding.right <= width))
	);
};
